import React, { useEffect } from "react"
import { Box, Container, Text, useColorMode } from "@chakra-ui/react"
import Layout from "../components/Layout/Layout"
import HeroSection from "../components/HeroSection"

const Datenschutz = () => {
  return (
    <Layout title='Datenschutz' colorMode='light'>
      <Box bg='brand.pearl'>
        <Container variant='layoutContainer' pt={{ sm: 24, base: 20 }} px={{ md: 8, base: 4 }}>
          <HeroSection
            alt='Band Majanko vor einer Hafenmauer'
            bgColor='brand.sand'
            justifyContent='center'
            minH={{ md: '650px', base: '400px' }}
            maxH={{ md: '650px', base: '400px' }}
            video={false}
            mediaOpacity={1}
            mediaSrc='tree.png'
            parallaxOffsetEnd={0}
            parallaxOffsetStart={0}
          >
          </HeroSection>
        </Container>

        <Container variant='layoutContainer' bg='brand.pearl' px={{ md: 8, base: 0 }}>
          <Box px={8} py={16}>
            <Text textStyle='h1' mb={8}>Datenschutzerklärung</Text>
            <Box maxW='2xl'>

              <Text as='h2' textStyle='h3' mb={4}>1. Datenschutz auf einen Blick</Text>
              <Text as='h3' textStyle='h3' mb={8}>Allgemeine Hinweise</Text>
              <Text textStyle='p' mb={8}>
                Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten
                passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
                persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen
                Sie unserer unter diesem Text aufgeführten Datenschutzerklärung.
              </Text>
              <Text as='h3' textStyle='h3' mb={8}>Datenerfassung auf dieser Website</Text>
              <Text as='h4' textStyle='h4' mb={4}>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</Text>
              <Text textStyle='p' mb={8}>
                Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten
                können Sie dem Abschnitt „Hinweis zur Verantwortlichen Stelle“ in dieser Datenschutzerklärung entnehmen.
              </Text>
              <Text as='h4' textStyle='h4' mb={4}>Wie erfassen wir Ihre Daten?</Text>
              <Text textStyle='p' mb={8}>
                Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z. B. um
                Daten handeln, die Sie in ein Kontaktformular eingeben.
              </Text>
              <Text textStyle='p' mb={8}>
                Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch unsere ITSysteme erfasst. Das sind vor allem technische Daten (z. B. Internetbrowser, Betriebssystem oder Uhrzeit
                des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website betreten.
              </Text>
              <Text as='h4' textStyle='h4' mb={4}>Wofür nutzen wir Ihre Daten?</Text>
              <Text textStyle='p' mb={8}>
                Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere
                Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.
              </Text>
              <Text as='h4' textStyle='h4' mb={4}>Welche Rechte haben Sie bezüglich Ihrer Daten?</Text>
              <Text textStyle='p' mb={8}>
                Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer
                gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung oder
                Löschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben,
                können Sie diese Einwilligung jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht, unter
                bestimmten Umständen die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
              </Text>
              <Text textStyle='p' mb={8}>
                Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
              </Text>
              <Text textStyle='p' mb={8}>
                Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit an uns wenden.
              </Text>


              <Text as='h2' textStyle='h3' mb={4}>2. Hosting</Text>
              <Text as='h3' textStyle='h3' mb={8}>IONOS</Text>
              <Text textStyle='p' mb={8}>
                Wir hosten unsere Website bei IONOS SE. Anbieter ist die IONOS SE, Elgendorfer Str. 57, 56410
                Montabaur (nachfolgend IONOS). Wenn Sie unsere Website besuchen, erfasst IONOS verschiedene Logfiles
                inklusive Ihrer IP-Adressen. Details entnehmen Sie der Datenschutzerklärung von IONOS:
                <a href='https://www.ionos.de/terms-gtc/terms-privacy.' style={{ textDecoration: 'underline' }}>https://www.ionos.de/terms-gtc/terms-privacy.</a>
              </Text>
              <Text textStyle='p' mb={8}>
                Die Verwendung von IONOS erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Wir haben ein
                berechtigtes Interesse an einer möglichst zuverlässigen Darstellung unserer Website. Sofern eine
                entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art.
                6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von Cookies oder den
                Zugriff auf Informationen im Endgerät des Nutzers (z. B. Device-Fingerprinting) im Sinne des TTDSG
                umfasst. Die Einwilligung ist jederzeit widerrufbar.
              </Text>

              <Text as='h2' textStyle='h3' mb={4}>3. Allgemeine Hinweise und Pflichtinformationen</Text>
              <Text as='h3' textStyle='h3' mb={8}>Datenschutz</Text>
              <Text textStyle='p' mb={8}>
                Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre
                personenbezogenen Daten vertraulich und entsprechend den gesetzlichen Datenschutzvorschriften sowie
                dieser Datenschutzerklärung.
              </Text>
              <Text textStyle='p' mb={8}>
                Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben.
                Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende
                Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie
                und zu welchem Zweck das geschieht.
              </Text>
              <Text textStyle='p' mb={8}>
                Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei der Kommunikation per E-Mail)
                Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
                möglich.
              </Text>

              <Text as='h3' textStyle='h3' mb={8}>Hinweis zur verantwortlichen Stelle</Text>
              <Text textStyle='p' mb={8}>
                Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:
                <br></br><br></br>
                Majanko Bauer<br></br>
                Moselstraße 13<br></br>
                26382 Wilhelmshaven<br></br>
                <br></br><br></br>
                Telefon: 015901462820<br></br>
                E-Mail: majanko.bauer@gmail.com
                <br></br><br></br>
                Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen über
                die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z. B. Namen, E-Mail-Adressen o. Ä.)
                entscheidet.
              </Text>

              <Text as='h3' textStyle='h3' mb={8}>Speicherdauer</Text>
              <Text textStyle='p' mb={8}>
                Soweit innerhalb dieser Datenschutzerklärung keine speziellere Speicherdauer genannt wurde, verbleiben
                Ihre personenbezogenen Daten bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie ein
                berechtigtes Löschersuchen geltend machen oder eine Einwilligung zur Datenverarbeitung widerrufen,
                werden Ihre Daten gelöscht, sofern wir keine anderen rechtlich zulässigen Gründe für die Speicherung Ihrer
                personenbezogenen Daten haben (z. B. steuer- oder handelsrechtliche Aufbewahrungsfristen); im
                letztgenannten Fall erfolgt die Löschung nach Fortfall dieser Gründe.
              </Text>
              <Text as='h3' textStyle='h3' mb={8}>Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung auf dieser
                Website</Text>
              <Text textStyle='p' mb={8}>
                Sofern Sie in die Datenverarbeitung eingewilligt haben, verarbeiten wir Ihre personenbezogenen Daten auf
                Grundlage von Art. 6 Abs. 1 lit. a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO, sofern besondere Datenkategorien
                nach Art. 9 Abs. 1 DSGVO verarbeitet werden. Im Falle einer ausdrücklichen Einwilligung in die Übertragung
                personenbezogener Daten in Drittstaaten erfolgt die Datenverarbeitung außerdem auf Grundlage von Art.
                49 Abs. 1 lit. a DSGVO. Sofern Sie in die Speicherung von Cookies oder in den Zugriff auf Informationen in
                Ihr Endgerät (z. B. via Device-Fingerprinting) eingewilligt haben, erfolgt die Datenverarbeitung zusätzlich
                auf Grundlage von § 25 Abs. 1 TTDSG. Die Einwilligung ist jederzeit widerrufbar. Sind Ihre Daten zur
                Vertragserfüllung oder zur Durchführung vorvertraglicher Maßnahmen erforderlich, verarbeiten wir Ihre
                Daten auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Des Weiteren verarbeiten wir Ihre Daten, sofern diese
                zur Erfüllung einer rechtlichen Verpflichtung erforderlich sind auf Grundlage von Art. 6 Abs. 1 lit. c DSGVO.
                Die Datenverarbeitung kann ferner auf Grundlage unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f
                DSGVO erfolgen. Über die jeweils im Einzelfall einschlägigen Rechtsgrundlagen wird in den folgenden
                Absätzen dieser Datenschutzerklärung informiert.
              </Text>

              <Text as='h3' textStyle='h3' mb={8}>Hinweis zur Datenweitergabe in die USA und sonstige Drittstaaten</Text>
              <Text textStyle='p' mb={8}>
                Wir verwenden unter anderem Tools von Unternehmen mit Sitz in den USA oder sonstigen
                datenschutzrechtlich nicht sicheren Drittstaaten. Wenn diese Tools aktiv sind, können Ihre
                personenbezogene Daten in diese Drittstaaten übertragen und dort verarbeitet werden. Wir weisen darauf
                hin, dass in diesen Ländern kein mit der EU vergleichbares Datenschutzniveau garantiert werden kann.
                Beispielsweise sind US-Unternehmen dazu verpflichtet, personenbezogene Daten an Sicherheitsbehörden
                herauszugeben, ohne dass Sie als Betroffener hiergegen gerichtlich vorgehen könnten. Es kann daher nicht
                ausgeschlossen werden, dass US-Behörden (z. B. Geheimdienste) Ihre auf US-Servern befindlichen Daten zu
                Überwachungszwecken verarbeiten, auswerten und dauerhaft speichern. Wir haben auf diese
                Verarbeitungstätigkeiten keinen Einfluss.
              </Text>

              <Text as='h3' textStyle='h3' mb={8}>Widerruf Ihrer Einwilligung zur Datenverarbeitung</Text>
              <Text textStyle='p' mb={8}>
                Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine
                bereits erteilte Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
                Datenverarbeitung bleibt vom Widerruf unberührt.
              </Text>

              <Text as='h3' textStyle='h3' mb={8}>Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen
                Direktwerbung (Art. 21 DSGVO)</Text>
              <Text textStyle='p' mb={8}>
                WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO
                ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN
                SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN
                WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES
                PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT,
                ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN,
                WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES
                SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG
                NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN ODER DIE
                5 / 9
                VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON
                RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).
              </Text>
              <Text textStyle='p' mb={8}>
                WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN,
                SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE
                BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG
                EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN
                VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN
                ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH
                NACH ART. 21 ABS. 2 DSGVO).
              </Text>

              <Text as='h3' textStyle='h3' mb={8}>Beschwerderecht bei der zuständigen Aufsichtsbehörde</Text>
              <Text textStyle='p' mb={8}>
                Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer
                Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes
                oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht unbeschadet anderweitiger
                verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
              </Text>
              <Text as='h3' textStyle='h3' mb={8}>Recht auf Datenübertragbarkeit</Text>
              <Text textStyle='p' mb={8}>
                Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags
                automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format
                aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen
                verlangen, erfolgt dies nur, soweit es technisch machbar ist.
              </Text>
              <Text as='h3' textStyle='h3' mb={8}>Auskunft, Löschung und Berichtigung</Text>
              <Text textStyle='p' mb={8}>
                Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche
                Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den
                Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung dieser Daten. Hierzu sowie
                zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit an uns wenden.
              </Text>
              <Text as='h3' textStyle='h3' mb={8}>Recht auf Einschränkung der Verarbeitung</Text>
              <Text textStyle='p' mb={8}>
                Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
                Hierzu können Sie sich jederzeit an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in
                folgenden Fällen:

                <Text as='ul'>
                  <Text as='li'>
                    Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, benötigen wir
                    in der Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die
                    Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
                  </Text>

                  <Text as='li'>
                    Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah/geschieht, können Sie
                    statt der Löschung die Einschränkung der Datenverarbeitung verlangen.
                  </Text>

                  <Text as='li'>
                    Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung,
                    Verteidigung oder Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht, statt der
                    Löschung die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
                  </Text>

                  <Text as='li'>
                    Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung zwischen
                    Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen
                    überwiegen, haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten
                    zu verlangen.
                  </Text>
                </Text>
              </Text>

              <Text textStyle='p' mb={8}>
                Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese Daten – von
                ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder
                Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder
                juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Europäischen Union oder
                eines Mitgliedstaats verarbeitet werden.
              </Text>


              <Text as='h2' textStyle='h3' mb={4}>4. Datenerfassung auf dieser Website</Text>
              <Text as='h3' textStyle='h3' mb={8}>Anfrage per E-Mail, Telefon oder Telefax</Text>
              <Text textStyle='p' mb={8}>
                Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage inklusive aller daraus
                hervorgehenden personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens
                bei uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
              </Text>
              <Text textStyle='p' mb={8}>
                Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit
                der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
                erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an der
                effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
                Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde; die Einwilligung ist jederzeit
                widerrufbar
              </Text>
              <Text textStyle='p' mb={8}>
                Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben bei uns, bis Sie uns zur Löschung
                auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt
                (z. B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen –
                insbesondere gesetzliche Aufbewahrungsfristen – bleiben unberührt.
              </Text>

              <Text as='h2' textStyle='h3' mb={4}>5. Plugins und Tools</Text>
              <Text as='h3' textStyle='h3' mb={8}>YouTube mit erweitertem Datenschutz</Text>
              <Text textStyle='p' mb={8}>
                Diese Website bindet Videos der YouTube ein. Betreiber der Seiten ist die Google Ireland Limited („Google“),
                Gordon House, Barrow Street, Dublin 4, Irland.
              </Text>
              <Text textStyle='p' mb={8}>
                Wir nutzen YouTube im erweiterten Datenschutzmodus. Dieser Modus bewirkt laut YouTube, dass
                YouTube keine Informationen über die Besucher auf dieser Website speichert, bevor diese sich das Video
                ansehen. Die Weitergabe von Daten an YouTube-Partner wird durch den erweiterten Datenschutzmodus
                hingegen nicht zwingend ausgeschlossen. So stellt YouTube – unabhängig davon, ob Sie sich ein Video
                ansehen – eine Verbindung zum Google DoubleClick-Netzwerk her.
              </Text>
              <Text textStyle='p' mb={8}>
                Sobald Sie ein YouTube-Video auf dieser Website starten, wird eine Verbindung zu den Servern von
                YouTube hergestellt. Dabei wird dem YouTube-Server mitgeteilt, welche unserer Seiten Sie besucht haben.
                Wenn Sie in Ihrem YouTube-Account eingeloggt sind, ermöglichen Sie YouTube, Ihr Surfverhalten direkt
                Ihrem persönlichen Profil zuzuordnen. Dies können Sie verhindern, indem Sie sich aus Ihrem YouTubeAccount ausloggen.
              </Text>
              <Text textStyle='p' mb={8}>
                Des Weiteren kann YouTube nach Starten eines Videos verschiedene Cookies auf Ihrem Endgerät speichern
                oder vergleichbare Wiedererkennungstechnologien (z. B. Device-Fingerprinting) einsetzen. Auf diese Weise
                kann YouTube Informationen über Besucher dieser Website erhalten. Diese Informationen werden u. a.
                verwendet, um Videostatistiken zu erfassen, die Anwenderfreundlichkeit zu verbessern und
                Betrugsversuchen vorzubeugen.
              </Text>
              <Text textStyle='p' mb={8}>
                Gegebenenfalls können nach dem Start eines YouTube-Videos weitere Datenverarbeitungsvorgänge
                ausgelöst werden, auf die wir keinen Einfluss haben.
              </Text>
              <Text textStyle='p' mb={8}>
                Die Nutzung von YouTube erfolgt im Interesse einer ansprechenden Darstellung unserer Online-Angebote.
                Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine entsprechende
                Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a
                DSGVO und § 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf
                Informationen im Endgerät des Nutzers (z. B. Device-Fingerprinting) im Sinne des TTDSG umfasst. Die
                Einwilligung ist jederzeit widerrufbar.
              </Text>
              <Text textStyle='p' mb={8}>
                Weitere Informationen über Datenschutz bei YouTube finden Sie in deren Datenschutzerklärung unter:
                <a href='https://policies.google.com/privacy?hl=de' style={{ textDecoration: 'underline' }}>https://policies.google.com/privacy?hl=de.</a>
              </Text>
              <Text fontSize='10px'>
                  Quelle:  <a href='https://www.e-recht24.de/' style={{ textDecoration: 'underline' }}>e-recht24.de</a>
              </Text>

            </Box>

          </Box>
        </Container>
      </Box>
    </Layout>
  )
}

export default Datenschutz